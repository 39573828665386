<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">{{ lang.label }} Section</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                v-slot="{ passed, failed,errors }"
                name="The Name"
                rules=""
                :vid="lang.value +'name'">
                <fg-input v-model="formData.name"
                          :error="failed ? errors[0]: null"
                          fou
                          label="name"
                          name="name"
                          type="text">
                </fg-input>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ passed, failed,errors }"
                name="The Title"
                rules=""
                :vid="lang.value +'title'">
                <fg-input v-model="formData.title"
                          :error="failed ? errors[0]: null"
                          fou
                          label="Title"
                          name="title"
                          type="text">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="en.content"
                rules=""
                name="The Content"
                v-slot="{ passed, failed,errors }">
                <fg-text type="text"
                         :error="failed ? errors[0]: null"
                         label="Content"
                         name="content"
                         fou
                         v-model="formData.content">
                </fg-text>
              </ValidationProvider>
            </div>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import {Option, Select} from 'element-ui'
import {extend} from "vee-validate";
import {email, regex, required} from "vee-validate/dist/rules";
import Editor from '@tinymce/tinymce-vue'
import FormGroupTextArea from "@/components";

extend("required", required);
extend("email", email);
extend("regex", regex);

export default {
  components: {
    FormGroupTextArea,
    [Select.name]: Select,
    [Option.name]: Option,
    'editor': Editor
  },
  props: {
    lang: {
      type: Object
    },
    editMode: {
      type: Boolean,
      default: false
    },
    isSubmit: {
      type: Boolean,
      default: false
    },
    formData: {
      type: Object,
      default() {
        return {
          name: "",
          title: "",
          organization: "",
          location: "",
          content: "",
        }
      }
    },
  },
  data() {
    return {
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        if (res) {
          this.$emit('submit-step', this.formData, this.lang.value, this.isSubmit)
        } else {
          this.$emit('on-validated', res)
        }
        return res
      });
    },
  }
}
</script>
<style>
</style>
