<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <h5 class="text-center">English Section</h5>
      <div class="row">
        <div class="col-md-12 col-sm-12">
          <div class="row">
            <div class="col-md-6">
              <ValidationProvider
                v-slot="{ passed, failed,errors }"
                name="The Name"
                rules="required"
                vid="en.name">
                <fg-input v-model="formData.name"
                          :error="failed ? errors[0]: null"
                          fou
                          label="name"
                          name="name"
                          type="text">
                </fg-input>
              </ValidationProvider>
            </div>
            <div class="col-md-6">
              <ValidationProvider
                v-slot="{ passed, failed,errors }"
                name="The Title"
                rules=""
                vid="en.title">
                <fg-input v-model="formData.title"
                          :error="failed ? errors[0]: null"
                          fou
                          label="Title"
                          name="title"
                          type="text">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <fg-select
                name="forums"
                size="large"
                filterable
                clearable
                multiple
                placeholder="Select Forums"
                :input-classes="'select-default'"
                :label="'Forums'"
                :list="builderData.forumList"
                :listItemLabel="'title'"
                :listItemValue="'id'"
                v-model="formData.forums">
              </fg-select>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="en.website"
                rules=""
                name="The Website"
                v-slot="{ passed, failed,errors }">
                <fg-input type="text"
                          :error="failed ? errors[0]: null"
                          label="Website"
                          name="website"
                          fou
                          v-model="formData.website">
                </fg-input>
              </ValidationProvider>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <ValidationProvider
                vid="en.content"
                rules=""
                name="The Content"
                v-slot="{ passed, failed,errors }">
                <fg-text type="text"
                         :error="failed ? errors[0]: null"
                         label="Content"
                         name="content"
                         fou
                         v-model="formData.content">
                </fg-text>
              </ValidationProvider>
            </div>
          </div>
          <div class="form-group">
            <label>Image</label>
            <el-tooltip   placement="right">
              <div slot="content">(495 * 343)</div>
              <span style="margin: 0 5px;">
                        <svg class="bi bi-question-circle" fill="currentColor" height="16" viewBox="0 0 16 16"
                             width="16" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                      <path
                        d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                    </svg>
                    </span>
            </el-tooltip>
            <single-file-uploader-file-manager
              :preview-width="'200px'"
              :preview-height="'200px'"
              v-model="formData.image"
              :accepted-files="imagesExtensions">
            </single-file-uploader-file-manager>
          </div>
          <div class="form-group pt-4">
            <label>Is Active</label>&nbsp;
            <l-switch v-model="formData.is_active">
              <i slot="on" class="fa fa-check"></i>
              <i slot="off" class="fa fa-times"></i>
            </l-switch>
          </div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
import {extend} from "vee-validate";
import {email, required} from "vee-validate/dist/rules";
import {Option, Select, Tooltip} from "element-ui";
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import SingleFileUploaderFileManager from "../../../components/FileManager/SingleFileUploaderFileManager";

extend("required", required);
extend("email", email);

export default {
  components: {
    SingleFileUploaderFileManager,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },
  props: {
    lang: {
      type: String,
      default: 'en'
    },
    editMode: {
      type: Boolean,
      default: false
    },
    builderData: {},
    formData: {
      type: Object,
      default() {
        return {
          name: "",
          title: "",
          content: "",
          image: "",
          is_active: true,
          website: "",
        }
      }
    },
  },
  data() {
    return {
      editorConfig: this.getEditorConfig(),
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,
      imagesExtensions: this.getImagesExtensions(),
    };
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then(res => {
        if (res) {
          this.$emit('submit-step', this.formData, this.lang)
        } else {
          this.$emit('on-validated', res)
        }
        return res
      });
    },
  }
}
</script>
<style>
</style>
